'use client';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { createContext, FunctionComponent, PropsWithChildren } from 'react';

export type PlanContextShape = {
    showCademyBranding: boolean;
    brandColor: string;
    onBrandColor: string;
    brandHoverColor: string;
};

export const PlanContext = createContext<PlanContextShape>({
    showCademyBranding: true,
    brandColor: 'var(--cademy-blue)',
    onBrandColor: '#FFFFFF',
    brandHoverColor: 'color-mix(in srgb, white, var(--cademy-blue) 10%)',
});

type EducatorPlanContextProps = {
    whitelabellingEnabled: PublicEducator['whitelabellingEnabled'];
    branding: PublicEducator['branding'];
};
export const EducatorPlanContext: FunctionComponent<
    PropsWithChildren<EducatorPlanContextProps>
> = ({ children, whitelabellingEnabled, branding }) => {
    const planContext: PlanContextShape = {
        showCademyBranding: whitelabellingEnabled !== true,
        brandColor: branding?.color_brand || 'var(--cademy-blue)',
        onBrandColor: branding?.on_color_brand || '#FFFFFF',
        brandHoverColor: branding?.color_brand
            ? `color-mix(in srgb, white, ${branding.color_brand} 10%)`
            : 'color-mix(in srgb, white, var(--cademy-blue) 10%)',
    };

    return (
        <PlanContext.Provider value={planContext}>
            <div
                style={{
                    // @ts-expect-error css variables are not typed for inline styles
                    '--brand-color': planContext.brandColor,
                    '--on-brand-color': planContext.onBrandColor,
                    '--brand-hover-color': planContext.brandHoverColor,
                }}
            >
                {children}
            </div>
        </PlanContext.Provider>
    );
};
